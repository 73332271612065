import styled, { css, DefaultTheme } from 'styled-components';

import { HexagonProps } from './Hexagon';

const hexagonVariant = (theme: DefaultTheme) => ({
  green_500: {
    background: theme.colors.green_500
  },
  purple: {
    background: theme.colors.green_100
  },
  blue: {
    background: `linear-gradient(90deg, ${theme.colors.green_100} 0%, ${theme.colors.light} 100%)`
  },
  mediumBlue: {
    background: theme.colors.mediumBlue
  },
  prelude: {
    background: theme.colors.lighter
  },
  white: {
    background: theme.colors.white
  },
  backgroundLight: {
    background: theme.colors.grey_25
  },
  backgroundMedium: {
    background: theme.colors.backgroundMedium
  },
  transparent: {
    background: 'transparent'
  }
});

const defaultPolygon = `polygon(
  45% 1.33975%,
  46.5798% 0.60307%,
  48.26352% 0.15192%,
  50% 0%,
  51.73648% 0.15192%,
  53.4202% 0.60307%,
  55% 1.33975%,
  89.64102% 21.33975%,
  91.06889% 22.33956%,
  92.30146% 23.57212%,
  93.30127% 25%,
  94.03794% 26.5798%,
  94.48909% 28.26352%,
  94.64102% 30%,
  94.64102% 70%,
  94.48909% 71.73648%,
  94.03794% 73.4202%,
  93.30127% 75%,
  92.30146% 76.42788%,
  91.06889% 77.66044%,
  89.64102% 78.66025%,
  55% 98.66025%,
  53.4202% 99.39693%,
  51.73648% 99.84808%,
  50% 100%,
  48.26352% 99.84808%,
  46.5798% 99.39693%,
  45% 98.66025%,
  10.35898% 78.66025%,
  8.93111% 77.66044%,
  7.69854% 76.42788%,
  6.69873% 75%,
  5.96206% 73.4202%,
  5.51091% 71.73648%,
  5.35898% 70%,
  5.35898% 30%,
  5.51091% 28.26352%,
  5.96206% 26.5798%,
  6.69873% 25%,
  7.69854% 23.57212%,
  8.93111% 22.33956%,
  10.35898% 21.33975%
);`;

export const StyledHexagon = styled.div<HexagonProps>`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  z-index: ${({ zIndex }) => zIndex || 0};
  opacity: ${({ opacity }) => opacity};
  height: ${({ width, height }) => (height ? height : width)};
  width: ${({ width }) => width};
  ${({ variant, theme }) => hexagonVariant(theme)[variant]};
  ${({ clipPath }) =>
    clipPath !== false &&
    css`
      clip-path: ${defaultPolygon};
    `};
  ${({ rotate }) =>
    rotate
      ? css<{ width: HexagonProps['width'] }>`
          transform: rotate(${rotate});
        `
      : css<{ width: HexagonProps['width'] }>`
          transform: unset;
        `};
`;

export const StyledChildrenWrapper = styled.div<{
  rotate: HexagonProps['rotate'];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(-${rotate});
    `};
`;

export type { HexagonProps };
