import React, { FC, ReactNode } from 'react';

import { StyledChildrenWrapper, StyledHexagon } from './Hexagon.styles';

export type HexagonProps = {
  width: string;
  height?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: string | number;
  variant:
    | 'blue'
    | 'purple'
    | 'white'
    | 'prelude'
    | 'backgroundLight'
    | 'transparent'
    | 'backgroundMedium'
    | 'mediumBlue'
    | 'green_500';
  position?: 'relative' | 'absolute';
  opacity?: number;
  children?: ReactNode | null;
  rotate?: string;
  clipPath?: boolean;
};

const Hexagon: FC<HexagonProps> = ({
  width,
  height,
  top,
  bottom,
  left,
  right,
  rotate,
  zIndex,
  variant,
  position,
  opacity,
  children,
  clipPath
}) => (
  <StyledHexagon
    width={width}
    height={height}
    top={top}
    bottom={bottom}
    left={left}
    right={right}
    zIndex={zIndex}
    variant={variant}
    position={position}
    opacity={opacity}
    rotate={rotate}
    clipPath={clipPath}
  >
    {children && <StyledChildrenWrapper rotate={rotate}>{children}</StyledChildrenWrapper>}
  </StyledHexagon>
);

export default Hexagon;
