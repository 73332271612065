import styled, { css } from 'styled-components';

import { mq } from 'theme/media';

import { ContentBoxProps } from './ContentBox';

const crops = {
  leftToRight: 'polygon(0 0, calc(100% - 100px) 0, 100% 100%, 0% 100%)',
  rightToLeft: 'polygon(0 0, 100% 0, calc(100% - 400px) 100%, 0% 100%)'
};

const columnColors = {
  dark: ({ theme }) => theme.colors.darkGray,
  white: ({ theme }) => theme.colors.white,
  violet: ({ theme }) => theme.gradients.purple,
  purple: ({ theme }) => theme.gradients.primaryReverse,
  light: ({ theme }) => theme.colors.grey_25
};

export const StyledMainWrapper = styled.div<{
  bgColor?: ContentBoxProps['bgColor'];
}>`
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${({ theme }) => (bgColor ? bgColor : theme.colors.lightGray)};
      ${mq.xxLarge`
        background-color: unset;
      `}
    `}
`;

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  max-width: 1160px;
`;

export const StyledLeftColumn = styled.div<{
  bgColor?: ContentBoxProps['bgColor'];
  bigShapes?: ContentBoxProps['bigShapes'];
  crop?: ContentBoxProps['crop'];
  leftColumnColor?: ContentBoxProps['leftColumnColor'];
  hasImage?: ContentBoxProps['hasImage'];
}>`
  position: relative;
  margin-top: 40%;
  flex: 4;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${({ bgColor }) =>
    bgColor &&
    css`
      margin: 0;
    `}

  ${({ hasImage }) =>
    hasImage &&
    css`
      ${mq.xsLarge`
      flex: 16;
    `}
    `}

  ${({ crop }) =>
    crop &&
    css`
      flex: 4;
    `}

  ${({ bigShapes }) =>
    bigShapes &&
    css`
      margin-top: 0;
    `}

  ${mq.large`
    margin-top: 0;

    ${({ bigShapes }) =>
      bigShapes &&
      css`
        margin-left: 0;
        padding-right: 68px;
        width: 50%;
      `}

    &::before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      right: 0;
      top: 0;
      background: ${({ leftColumnColor }) => columnColors[leftColumnColor]};
      clip-path: ${({ crop }) => crop && crops[crop]};
      z-index: 1;

      ${({ crop }) =>
        crop &&
        css`
          right: ${({ theme }) => theme.spacing(25)};
        `}
    }
  `}
`;

export const StyledBackground = styled.div<{
  rightColumnColor?: ContentBoxProps['rightColumnColor'];
}>`
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  background: ${({ rightColumnColor }) => columnColors[rightColumnColor]};
  height: 100%;
  opacity: 0.9;

  ${mq.large`
    width: 100vw;
    opacity: 1;
  `}
`;

export const StyledRightColumn = styled.div<{
  bigShapes?: ContentBoxProps['bigShapes'];
  crop?: ContentBoxProps['crop'];
}>`
  flex: 8;
  position: relative;
  display: none;

  ${({ crop }) =>
    crop &&
    css`
      flex: 6;
    `}

  ${({ bigShapes }) =>
    bigShapes &&
    css`
      flex: none;
      width: 50%;
    `}

  ${mq.large`
    display: block;
  `}
`;

export const StyledWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const StyledMobileBackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${mq.large`
    display: none;
  `}
`;

export const StyledImageWrapper = styled.div<{
  bigShapes?: ContentBoxProps['bigShapes'];
}>`
  position: relative;
  height: 100%;
  width: 100%;

  ${({ bigShapes }) =>
    bigShapes &&
    css`
      ${mq.large`
      min-width: 992px;
    `}

      ${mq.xxxLarge`
      min-width: 1920px;
    `}
    `}
`;
