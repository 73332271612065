import React, { FC, ElementType } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Typography from 'components/atoms/Typography/Typography';
import { GenericProps } from 'components/atoms/Button/GenericButton';
import { Marked } from 'components/Marked/Marked';

import Button from 'utils/types/Button';
import { scrollToSection } from 'utils/scroll';

export type TextProps = {
  title?: MDXRemoteSerializeResult;
  description?: MDXRemoteSerializeResult;
  button?: Button & { variant?: GenericProps['variant'] };
  renderAsElement?: ElementType;
};

import { StyledTextContainer, StyledDescription, StyledButtonContainer, StyledTitleWrapper } from './Text.styles';

const Text: FC<TextProps> = ({ title, description, button, renderAsElement }) => (
  <StyledTextContainer>
    {title && (
      <StyledTitleWrapper>
        <Typography variant="body2" color="secondary">
          <Marked>{title}</Marked>
        </Typography>
      </StyledTitleWrapper>
    )}
    {description && (
      <StyledDescription>
        <Typography variant="h3" color="secondary" weight="200" renderAs={renderAsElement}>
          <Marked>{description}</Marked>
        </Typography>
      </StyledDescription>
    )}
    {button && (
      <StyledButtonContainer>
        {button.href.startsWith('#') ? (
          <FuncButton
            variant={button.variant || 'secondary-white'}
            onClick={() => scrollToSection(button.href.substring(1))}
          >
            {button.buttonCopy}
          </FuncButton>
        ) : (
          <LinkedButton href={button.href} variant={button.variant || 'primary'}>
            {button.buttonCopy}
          </LinkedButton>
        )}
      </StyledButtonContainer>
    )}
  </StyledTextContainer>
);

export default Text;
