import React, { FC, ImgHTMLAttributes } from 'react';
import Image from 'next/image';

import { SIZES } from './MobileOnlyImage.utils';

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export type MobileOnlyImageProps = Omit<ImageProps, 'srcSet' | 'sizes'> & {
  coverStyle?: boolean;
  quality?: number;
};

export const MobileOnlyImage: FC<MobileOnlyImageProps> = ({ coverStyle = false, src, quality = 75 }) => (
  <Image
    loading="eager"
    src={src}
    sizes={SIZES}
    quality={quality}
    {...(coverStyle ? { layout: 'fill' } : {})}
    {...(coverStyle ? { objectFit: 'cover' } : {})}
  />
);
