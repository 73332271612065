import { windowScroll } from 'seamless-scroll-polyfill';

export const scroll = (top = 0): void =>
  windowScroll({
    behavior: 'smooth',
    top
  });

export const scrollToSection = (sectionId: string): void => {
  const header = document.getElementById('header');
  const pathElement = document.getElementById(sectionId);

  if (pathElement) {
    windowScroll({
      behavior: 'smooth',
      top: pathElement.getBoundingClientRect().top + window.scrollY - header.offsetHeight
    });
  }
};
