import React, { FC, ReactNode } from 'react';
import Head from 'next/head';

import { getNextImgUrl } from 'helpers/image';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';
import breakpoints from 'theme/breakpoints';

import Container from 'components/atoms/Container/Container';
import { MobileOnlyImage } from 'components/MobileOnlyImage/MobileOnlyImage';
import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import useMedia from 'hooks/useMedia';
import { Shape } from 'components/atoms/Shape/Shape';
import Text from './Text/Text';
import {
  StyledContainer,
  StyledLeftColumn,
  StyledRightColumn,
  StyledBackground,
  StyledMobileBackgroundWrapper,
  StyledImageWrapper,
  StyledWrapper,
  StyledMainWrapper
} from './ContentBox.styles';

export type ColumnColors = 'dark' | 'white' | 'violet' | 'light' | 'purple';

export type ContentBoxProps = {
  bgColor?: string;
  leftColumnContent?: ReactNode;
  rightColumnContent?: ReactNode;
  leftColumnColor?: ColumnColors;
  rightColumnColor?: ColumnColors;
  crop?: 'leftToRight' | 'rightToLeft';
  geoShapes?: ReactNode;
  bigShapes?: boolean;
  mobileBackground?: UploadedImg;
  hasImage?: boolean;
};

interface ContentBox extends FC<ContentBoxProps> {
  Text: typeof Text;
  ImageWrapper: typeof StyledImageWrapper;
}

const MOBILE_BACKGROUND_QUALITY = 50;

const ContentBox: ContentBox = ({
  bgColor = '',
  leftColumnColor = 'dark',
  rightColumnColor = 'dark',
  crop,
  rightColumnContent = null,
  leftColumnContent = null,
  geoShapes = null,
  bigShapes = false,
  mobileBackground,
  hasImage = false
}) => {
  const { isMobile } = useMedia();

  return (
    <StyledMainWrapper bgColor={bgColor}>
      {mobileBackground && (
        <Head>
          <link
            rel="preload"
            href={getNextImgUrl(fromCMS(mobileBackground.file.url), {
              quality: MOBILE_BACKGROUND_QUALITY,
              width: breakpoints.small
            })}
            as="image"
            media={`(max-width: ${breakpoints.mobileMax}px)`}
          />
        </Head>
      )}
      <StyledWrapper>
        {mobileBackground && (
          <StyledMobileBackgroundWrapper>
            <MobileOnlyImage
              alt={mobileBackground.alt}
              quality={MOBILE_BACKGROUND_QUALITY}
              src={fromCMS(mobileBackground.file.url)}
              coverStyle
            />
          </StyledMobileBackgroundWrapper>
        )}
        {(isMobile || rightColumnContent) && <StyledBackground rightColumnColor={rightColumnColor} />}
        <Container>
          <StyledContainer>
            {bigShapes && (
              <>
                <Hexagon
                  width="366px"
                  variant="green_500"
                  left={isMobile ? '-50%' : '-380px'}
                  top={isMobile ? '10px' : '0'}
                  opacity={0.4}
                  rotate="43deg"
                  zIndex={2}
                />
                <Hexagon
                  width="310px"
                  variant="white"
                  right={isMobile ? '-220px' : '-250px'}
                  bottom={isMobile ? '-180px' : '-100px'}
                  opacity={0.3}
                  zIndex={2}
                />
                <Shape
                  src="/shapes/reviews_corner.png"
                  width={isMobile ? 61 : 97}
                  height={isMobile ? 65 : 103}
                  position="absolute"
                  bottom="-40px"
                  right={isMobile ? '60px' : '150px'}
                  zIndex={2}
                />
              </>
            )}
            {geoShapes}
            {leftColumnContent && (
              <StyledLeftColumn
                bgColor={bgColor}
                bigShapes={bigShapes}
                leftColumnColor={leftColumnColor}
                crop={crop}
                hasImage={hasImage}
              >
                {leftColumnContent}
              </StyledLeftColumn>
            )}
            {rightColumnContent && <StyledRightColumn bigShapes={bigShapes}>{rightColumnContent}</StyledRightColumn>}
          </StyledContainer>
        </Container>
      </StyledWrapper>
    </StyledMainWrapper>
  );
};

ContentBox.Text = Text;

ContentBox.ImageWrapper = StyledImageWrapper;

export default ContentBox;
